import React from "react";
import PropTypes from "prop-types";
import { uniqueId } from "lodash";
import classnames from "classnames";

import { ReactComponent as ValidationErrorIcon } from "../images/validation_error_icon.svg";

const DateTimeInput = ({
  label,
  placeholder,
  name,
  inputRef,
  errorMessage,
}) => {
  const id = uniqueId();
  const hasError = !!errorMessage;

  // Needed for Safari
  // https://stackoverflow.com/questions/43747521/mobile-safari-10-3-1-datetime-local-enter-a-valid-value-error
  const onChange = (event) => {
    if (event.target.value === "") {
      event.target.value = undefined;
    }
    event.target.value = event.target.value.substr(0, 16);
  };

  const inputClassNames = classnames(
    "form-input h-10 w-full transition duration-150 ease-in-out",
    {
      "border-red-300": hasError,
      "text-red-900": hasError,
      "placeholder-red-300": hasError,
      "focus:border-red-300": hasError,
      "focus:shadow-outline-red": hasError,
    }
  );

  return (
    <div>
      <label
        htmlFor={id}
        className="block text-sm font-medium leading-5 text-gray-700"
      >
        {label}
      </label>
      {hasError && <p className="mt-2 text-sm text-red-600">{errorMessage}</p>}
      <div className="mt-1 relative rounded-md shadow-sm">
        <input
          id={id}
          className={inputClassNames}
          placeholder={placeholder}
          name={name}
          type="datetime-local"
          onChange={onChange}
          ref={inputRef}
        />
        {hasError && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <ValidationErrorIcon />
          </div>
        )}
      </div>
    </div>
  );
};

DateTimeInput.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
};

export default DateTimeInput;
