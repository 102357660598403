import { get, isEmpty, flow, split, initial, join, map } from "lodash/fp";

const getRoute = (path, routes, variables = {}, current = "") => {
  if (isEmpty(path)) return routes.base + current;

  const newPath = flow(split("."), initial, join("."))(path);

  const key = get(path)(routes);
  const route = typeof key === "string" ? key : key.base;
  const populated = flow(
    split(/(?=:)/),
    map((el) => get(el.substring(1), variables) || el),
    join("")
  )(route);

  return getRoute(newPath, routes, variables, populated + current);
};

export default getRoute;
