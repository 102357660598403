import React from "react";
import SplashScreen from "../../images/background_food.jpg";
import classnames from "classnames";

const sectionStyle = {
  backgroundImage: `url(${SplashScreen})`,
};

export const PADDING_IN_PX =
  1.25 * parseFloat(getComputedStyle(document.documentElement).fontSize);

const Main = ({ padding, children }) => {
  const mainDivStyle = {
    minHeight: window.innerHeight,
  };
  const mainDivClasses = classnames("max-w-lg bg-white ml-auto", {
    "p-5": padding !== "none",
  });

  return (
    <div className="bg-cover" style={sectionStyle}>
      <div className={mainDivClasses} style={mainDivStyle}>
        {children}
      </div>
    </div>
  );
};

export default Main;
