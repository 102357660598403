import React from "react";

const StatsBox = () => {
  return (
    <>
      <div class="mt-1 text-4xl text-center leading-9 font-semibold text-gray-900">
        1,492,471
      </div>

      <div className="border-b-2 border-orange-600">
        <div class="text-sm pb-4 text-center leading-5 font-medium text-gray-500 truncate">
          Meals Delivered
        </div>
      </div>
    </>
  );
};

export default StatsBox;
