import React from "react";
import { ApolloClient, InMemoryCache, gql } from "@apollo/client";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";

import HowToHelp from "./views/HowToHelp";
import PickupDetails from "./views/PickupRequest/PickupDetails";
import MoreDetails from "./views/PickupRequest/MoreDetails";
import DiscussDetails from "./views/PickupRequest/DiscussDetails";
import SelectLocation from "./views/PickupRequest/SelectLocation";
import PickupContact from "./views/PickupRequest/PickupContact";
import PickupSuccess from "./views/PickupRequest/PickupSuccess";
import appRoute from "./utils/appRoute";
import Landing from "./views/Landing";

const client = new ApolloClient({
  uri: `${process.env.REACT_APP_SERVER_URL}/graphql`,
  cache: new InMemoryCache(),
});

const App = () => {
  return (
    <ApolloProvider client={client}>
      <Router>
        <Switch>
          <Route path={appRoute("menu")}>
            <HowToHelp />
          </Route>
          <Route path={appRoute("forms.pickup.location")}>
            <SelectLocation />
          </Route>
          <Route path={appRoute("forms.pickup.details")}>
            <PickupDetails />
          </Route>
          <Route path={appRoute("forms.pickup.more_details")}>
            <MoreDetails />
          </Route>
          <Route path={appRoute("forms.pickup.discuss_details")}>
            <DiscussDetails />
          </Route>
          <Route path={appRoute("forms.pickup.contact")}>
            <PickupContact />
          </Route>
          <Route path={appRoute("forms.pickup.success")}>
            <PickupSuccess />
          </Route>
          <Route path={appRoute("")}>
            <Landing />
          </Route>
        </Switch>
      </Router>
    </ApolloProvider>
  );
};

export default App;
