import React, { useState } from "react";
import { useForm } from "react-hook-form";
import useFormPersist from "react-hook-form-persist";
import { yupResolver } from "@hookform/resolvers";
import { get } from "lodash";
import { object, string } from "yup";
import { useHistory } from "react-router-dom";
import classnames from "classnames";
import { gql, useMutation } from "@apollo/client";
import { DateTime } from "luxon";

import Layout from "../layouts/main";
import Input from "../../components/Input";
import TextArea from "../../components/TextArea";
import Button from "../../components/Button";
import useScrollToTop from "../../hooks/useScrollToTop";
import appRoute from "../../utils/appRoute";
import { PICKUP_DETAILS_PERSIST_KEY } from "./PickupDetails";
import { SELECT_LOCATION_PERSIST_KEY } from "./SelectLocation";
import { MORE_DETAILS_PERSIST_KEY } from "./MoreDetails";
import { DISCUSS_DETAILS_PERSIST_KEY } from "./DiscussDetails";

const validationSchema = object().shape({
  orgName: string().required().label("Organization name"),
  pickupName: string().required().label("Contact name"),
  pickupPhone: string().required().label("Phone"),
  pickupEmail: string().email().required().label("Email"),
  notes: string().label("Notes"),
  recipient: string().label("Recipient"),
});

export const PICKUP_CONTACT_PERSIST_KEY = "forms.pickup_contact";
const CREATE_DONATION = gql`
  mutation CreateDonation($input: CreateDonationInput!) {
    createDonation(input: $input) {
      donationId
    }
  }
`;
const PickupContact = () => {
  useScrollToTop();
  const { watch, setValue, register, handleSubmit, errors } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const showRecipient = watch("recipient", null);
  const [showAdvanced, setShowAdvanced] = useState(false);

  useFormPersist(
    PICKUP_CONTACT_PERSIST_KEY,
    { watch, setValue },
    { storage: window.localStorage }
  );

  const [createDonation, { data }] = useMutation(CREATE_DONATION);

  const history = useHistory();
  const pickupDetails = JSON.parse(
    window.localStorage.getItem(PICKUP_DETAILS_PERSIST_KEY)
  );
  const moreDetails = JSON.parse(
    window.localStorage.getItem(MORE_DETAILS_PERSIST_KEY)
  );
  const selectLocation = JSON.parse(
    window.localStorage.getItem(SELECT_LOCATION_PERSIST_KEY)
  );
  const discussDetails = JSON.parse(
    window.localStorage.getItem(DISCUSS_DETAILS_PERSIST_KEY)
  );
  const pickupBefore = DateTime.fromISO(discussDetails.pickupBefore).toISO();
  const pickupAfter = DateTime.fromISO(discussDetails.pickupAfter).toISO();
  const onSubmit = (data) => {
    createDonation({
      variables: {
        input: {
          pickupName: data.pickupName,
          pickupPhone: data.pickupPhone,
          notes: data.notes,
          title: pickupDetails.title,
          details: pickupDetails.details,
          quantity: pickupDetails.quantity,
          individuallyPackaged: moreDetails.individuallyPackaged,
          homemadeFood: moreDetails.homemadeFood,
          vehicle: discussDetails.vehicle,
          orgName: data.orgName,
          pickupEmail: data.pickupEmail,
          pickupBefore,
          pickupAfter,
          latitude: selectLocation.latitude,
          longitude: selectLocation.longitude,
          mapboxInfo: selectLocation.mapboxInfo,
          pickupAddress: selectLocation.pickupAddress,
        },
      },
    });
    delete discussDetails.pickupBefore;
    delete discussDetails.pickupAfter;
    delete pickupDetails.title;
    delete pickupDetails.details;
    delete pickupDetails.quantity;
    window.localStorage.setItem(
      PICKUP_DETAILS_PERSIST_KEY,
      JSON.stringify(pickupDetails)
    );
    window.localStorage.setItem(
      DISCUSS_DETAILS_PERSIST_KEY,
      JSON.stringify(discussDetails)
    );
    window.localStorage.setItem(SELECT_LOCATION_PERSIST_KEY, null);
    history.push(appRoute("forms.pickup.success"));
  };

  return (
    <Layout>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <div>
            <div>
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Add Details
              </h3>
            </div>
            <div className="mt-6 grid grid-cols-12 row-gap-6">
              <div className="col-span-12">
                <Input
                  label="Organization Name"
                  placeholder="Lucky Supermarket"
                  name="orgName"
                  inputRef={register}
                  errorMessage={get(errors, "orgName.message")}
                />
              </div>
              <div className="col-span-12">
                <Input
                  label="Contact Name"
                  placeholder="John Smith"
                  name="pickupName"
                  inputRef={register}
                  errorMessage={get(errors, "pickupName.message")}
                />
              </div>
              <div className="col-span-12">
                <Input
                  label="Phone"
                  placeholder="(555) 555-5555"
                  name="pickupPhone"
                  inputRef={register}
                  errorMessage={get(errors, "pickupPhone.message")}
                />
              </div>
              <div className="col-span-12">
                <Input
                  label="Email"
                  placeholder="john@example.com"
                  name="pickupEmail"
                  inputRef={register}
                  errorMessage={get(errors, "pickupEmail.message")}
                />
              </div>
              <div className="col-span-12">
                <TextArea
                  label="Pickup Notes"
                  placeholder="Please enter through 3rd street entrance"
                  name="notes"
                  inputRef={register}
                  errorMessage={get(errors, "notes.message")}
                  rows={1}
                />
              </div>
              <div
                className="col-span-12 text-center underline cursor-pointer"
                onClick={() => setShowAdvanced(!showAdvanced)}
              >
                Advanced
              </div>
              <div
                className={classnames("col-span-12", {
                  hidden: !showAdvanced && !showRecipient,
                })}
              >
                <Input
                  label="Donation Recipient (Optional)"
                  placeholder="Community Food Pantry"
                  name="recipient"
                  inputRef={register}
                  errorMessage={get(errors, "recipient.message")}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="mt-2 border-t border-gray-200 pt-3">
          <div className="flex justify-end">
            <span className="inline-flex rounded-md shadow-sm">
              <Button variant="white" linkTo={appRoute("menu")}>
                Cancel
              </Button>
            </span>
            <span className="ml-3">
              <Button variant="primary" type="submit">
                Save
              </Button>
            </span>
          </div>
        </div>
      </form>
    </Layout>
  );
};

export default PickupContact;
