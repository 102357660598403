import React from "react";
import classnames from "classnames";

const Modal = ({ open, onClose, children }) => {
  const handleOutsideClick = () => {
    onClose();
  };

  const backgroundClasses = classnames("fixed inset-0 transition-opacity", {
    "opacity-0": !open,
    "opacity-100": open,
    "ease-out duration-300": !open,
    "ease-in duration-200": open,
  });
  const mainContentClasses = classnames(
    "inline-block align-bottom bg-white rounded-lg m-auto pb-4 px-4 pt-5 text-left overflow-hidden shadow-xl transform transition-all sm:align-middle sm:max-w-lg sm:w-full sm:p-6",
    {
      "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95": !open,
      "opacity-100 translate-y-0 sm:scale-": open,
      "ease-out duration-300": !open,
      "ease-in duration-200": open,
    }
  );
  const outerDivClasses = classnames("fixed inset-0 overflow-y-auto", {
    "z-10": open,
    "z-0 invisible": !open,
  });

  return (
    <div className={outerDivClasses}>
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div
          className={backgroundClasses}
          aria-hidden="true"
          onClick={handleOutsideClick}
        >
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>

        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>

        <div
          className={mainContentClasses}
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default Modal;
