import React from "react";

import Modal from "../../components/Modal";
import Button from "../../components/Button";
import { externalRoutes } from "../../utils/appRoute";

const HomemadeFoodModal = ({ open, setOpen }) => {
  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <div>
        <div className="mt-3 text-center sm:mt-5">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            We don't accept homemade food
          </h3>
          <div className="mt-2">
            <p className="text-sm text-gray-500">
              In order to comply with regulations, we ask food to be
              professionally made and packaged. Visit{" "}
              <a
                className="font-medium text-gray-700 underline"
                target="_blank"
                href="https://www.findhelp.org/"
              >
                Find Help
              </a>
              — you can search local shelters in your area to see if they would
              like to accept the food.
            </p>
          </div>
        </div>
      </div>
      {/* <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense"> */}
      <div className="flex flex-col sm:flex-row">
        <div className="mt-2 sm:order-2 sm:w-2/4 sm:mx-1">
          <Button
            width="full"
            onClick={() => {
              setOpen(false);
            }}
          >
            OK
          </Button>
        </div>
        <div className="mt-2 sm:order-1 sm:w-2/4 sm:mx-1">
          <Button
            width="full"
            variant="white"
            linkTo={externalRoutes.FAQ}
            target="_blank"
          >
            See Guidelines
          </Button>
        </div>
      </div>
      {/* </div> */}
    </Modal>
  );
};

export default HomemadeFoodModal;
