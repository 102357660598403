import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

import Link from "./Link";

const Button = ({
  onClick,
  linkTo,
  children,
  type,
  size,
  width,
  variant,
  target = "_self",
}) => {
  const buttonClasses = classnames(
    "items-center border font-medium focus:outline-none transition ease-in-out duration-150",
    {
      "text-white": variant === "primary",
      "text-orange-700": variant === "secondary",
      "text-gray-700": variant === "white",
      "bg-orange-600": variant === "primary",
      "bg-orange-100": variant === "secondary",
      "bg-white": variant === "white",
      "hover:bg-orange-500": variant === "primary",
      "hover:bg-orange-50": variant === "secondary",
      "hover:text-gray-500": variant === "white",
      "focus:border-orange-700": variant === "primary",
      "focus:border-orange-300": variant === "secondary",
      "focus:border-blue-300": variant === "white",
      "focus:shadow-outline-orange": ["primary", "secondary"].includes(variant),
      "focus:shadow-outline-blue": variant === "white",
      "active:bg-orange-700": variant === "primary",
      "active:bg-orange-200": variant === "secondary",
      "active:text-gray-800": variant === "white",
      "active:bg-gray-50": variant === "white",
      "border-transparent": ["primary", "secondary"].includes(variant),
      "border-gray-300": variant === "white",
      "px-2.5": size === "xsmall",
      "px-3": size === "small",
      "px-4": ["medium", "large"].includes(size),
      "px-6": size === "xlarge",
      "py-1.5": size === "xsmall",
      "py-2": ["small", "medium", "large"].includes(size),
      "py-3": size === "xlarge",
      "text-xs": size === "xsmall",
      "text-sm": ["small", "medium"].includes(size),
      "text-base": ["large", "xlarge"].includes(size),
      "leading-4": ["xsmall", "small"].includes(size),
      "leading-5": ["medium"].includes(size),
      "leading-6": ["large", "xlarge"].includes(size),
      rounded: size === "xsmall",
      "rounded-md": ["small", "medium", "large", "xlarge"].includes(size),
      "w-full": width === "full",
      block: width === "full",
      "w-9/12": width === "w-9/12",
    }
  );
  return (
    <Link linkTo={linkTo} onClick={onClick} target={target}>
      <button type={type} className={buttonClasses}>
        {children}
      </button>
    </Link>
  );
};

Button.propTypes = {
  type: PropTypes.oneOf(["button", "submit"]),
  variant: PropTypes.oneOf(["primary", "secondary", "white"]),
  size: PropTypes.oneOf(["xsmall", "small", "medium", "large", "xlarge"]),
  width: PropTypes.oneOf(["full", "auto"]),
  target: PropTypes.oneOf(["_blank", "_self"]),
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  linkTo: PropTypes.string,
};

Button.defaultProps = {
  type: "button",
  size: "medium",
  variant: "primary",
  width: "auto",
};

export default Button;
