import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import { object, string, date } from "yup";
import { get } from "lodash";
import useFormPersist from "react-hook-form-persist";
import { useHistory } from "react-router-dom";

import Layout from "../layouts/main";
import Input from "../../components/Input";
import TextArea from "../../components/TextArea";
import Button from "../../components/Button";
import appRoute from "../../utils/appRoute";

export const PICKUP_DETAILS_PERSIST_KEY = "forms.pickup_details";

const PickupDetails = () => {
  const history = useHistory();

  const validationSchema = object().shape({
    title: string().required().label("Title"),
    quantity: string().required().label("Quantity"),
    details: string().required().label("Details"),
  });

  const { watch, setValue, register, handleSubmit, errors } = useForm({
    resolver: yupResolver(validationSchema),
  });

  useFormPersist(
    PICKUP_DETAILS_PERSIST_KEY,
    { watch, setValue },
    { storage: window.localStorage }
  );

  const onSubmit = () => {
    history.push(appRoute("forms.pickup.more_details"));
  };

  return (
    <Layout>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <div>
            <div>
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                What are you donating?
              </h3>
            </div>
            <div className="mt-6 grid grid-cols-12 row-gap-6">
              <div className="col-span-12">
                <Input
                  inputRef={register}
                  name="title"
                  label="Title"
                  placeholder="50 bags of bagels"
                  errorMessage={get(errors, "title.message")}
                />
              </div>
              <div className="col-span-12">
                <TextArea
                  inputRef={register}
                  name="details"
                  errorMessage={get(errors, "details.message")}
                  label="Details"
                  placeholder="20 bags of sesame, 20 bags of everything, 10 bags of plain"
                />
              </div>
              <div className="col-span-12">
                <Input
                  inputRef={register}
                  name="quantity"
                  errorMessage={get(errors, "quantity.message")}
                  label="Quantity"
                  placeholder="50 lbs"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="mt-24"></div>
        <div className="mt-8 border-t border-gray-200 pt-5">
          <div className="flex justify-end">
            <Button variant="white" linkTo={appRoute("menu")}>
              Cancel
            </Button>
            <span className="ml-3">
              <Button variant="primary" type="submit">
                Next
              </Button>
            </span>
          </div>
        </div>
      </form>
    </Layout>
  );
};

export default PickupDetails;
