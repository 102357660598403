import React from "react";
import { Link as ReactRouterDomLink } from "react-router-dom";
const isInternalUrl = (url) => url && url.charAt(0) === "/";

const Link = ({ linkTo, target = "_self", onClick, className, children }) => {
  if (isInternalUrl(linkTo)) {
    return (
      <ReactRouterDomLink
        to={linkTo}
        onClick={onClick}
        target={target}
        className={className}
      >
        {children}
      </ReactRouterDomLink>
    );
  }

  return (
    <a href={linkTo} onClick={onClick} target={target}>
      {children}
    </a>
  );
};

export default Link;
