import React, { useState } from "react";
import { useCombobox } from "downshift";

const LocationSelector = ({
  onLocationSelect,
  searchQuery,
  onSearchQueryChange,
}) => {
  const [inputItems, setInputItems] = useState([]);
  const {
    isOpen,
    selectedItem,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
  } = useCombobox({
    items: inputItems,
    itemToString: (item) => item.place_name,
    onSelectedItemChange: (item) => {
      const latitude = item.selectedItem.geometry.coordinates[1];
      const longitude = item.selectedItem.geometry.coordinates[0];
      onLocationSelect(latitude, longitude, item);
    },
    onInputValueChange: async function searchMapBox({ inputValue }) {
      const encodedSearchValue = encodeURI(inputValue);
      let response = await fetch(
        `https://fc-serve.herokuapp.com/mapbox/geocode_forward?query=${encodedSearchValue}`
      );
      const body = await response.json();
      if (response.status === 200) {
        setInputItems(body[0].features);
      } else {
        setInputItems([]);
      }
    },
  });
  const { onChange: onDownshiftChange } = getInputProps();
  return (
    <>
      <div {...getComboboxProps()}>
        <input
          {...getInputProps()}
          placeholder="Enter an address"
          className="h-10 p-4 w-full"
          value={searchQuery}
          onChange={(event) => {
            onSearchQueryChange(event.target.value);
            onDownshiftChange(event);
          }}
        />
      </div>
      <ul
        className="divide-y divide-gray-600 bg-opacity-50 bg-white"
        {...getMenuProps()}
      >
        {isOpen &&
          inputItems.map((item, index) => (
            <li
              className="py-4 mx-3 flex text-sm font-medium text-gray-900"
              style={
                highlightedIndex === index ? { backgroundColor: "#bde4ff" } : {}
              }
              key={`${item}${index}`}
              {...getItemProps({ item, index })}
            >
              {item.place_name}
            </li>
          ))}
      </ul>
    </>
  );
};

export default LocationSelector;
