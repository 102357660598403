import React, { useMemo, useRef } from "react";
import { Marker } from "react-leaflet";

export default function DraggableMarker({
  markerCoordinates,
  onMarkerDragEnd,
}) {
  const markerRef = useRef(null);

  const eventHandlers = useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current;
        if (marker != null) {
          const coordinates = marker.getLatLng();
          onMarkerDragEnd(coordinates.lat, coordinates.lng);
        }
      },
    }),
    []
  );
  return (
    <Marker
      draggable={true}
      eventHandlers={eventHandlers}
      position={markerCoordinates}
      ref={markerRef}
    />
  );
}
