import React from "react";

import Layout from "./layouts/main";
import MenuList from "../components/MenuList";
import appRoute, { externalRoutes } from "../utils/appRoute";
import { ReactComponent as DonateFoodIcon } from "../images/donate_food_icon.svg";
import { ReactComponent as VolunteerIcon } from "../images/volunteer_icon.svg";
import { ReactComponent as SponsorIcon } from "../images/sponsor_icon.svg";
import { ReactComponent as RequestFoodIcon } from "../images/request_food_icon.svg";

const HowToHelp = () => {
  const menuItems = [
    {
      title: "Donate Food",
      icon: DonateFoodIcon,
      linkTo: appRoute("forms.pickup.location"),
    },
    {
      title: "Request Food Donation",
      icon: RequestFoodIcon,
      linkTo: externalRoutes.REQUEST_FOOD,
      target: "_blank",
    },
    {
      title: "Sponsor Meals",
      icon: SponsorIcon,
      linkTo: externalRoutes.SPONSOR_MEALS,
      target: "_blank",
    },
    {
      title: "Volunteer",
      icon: VolunteerIcon,
      linkTo: externalRoutes.VOLUNTEER,
      target: "_blank",
    },
  ];

  return (
    <Layout>
      <div className="my-12 mx-4">
        <h2 className="text-3xl leading-9 font-extrabold tracking-tight text-gray-900">
          How will you help solve hunger?
          <div className="text-orange-600">Get involved and connect today.</div>
        </h2>
      </div>
      <MenuList items={menuItems} />
    </Layout>
  );
};

export default HowToHelp;
