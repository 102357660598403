import React from "react";
import PropTypes from "prop-types";

import MenuItemList from "./MenuItemList";

const MenuList = ({ items }) => {
  return (
    <div className="shadow bg-white bg-opacity-75">
      <ul>
        {items.map((item, index) => (
          <MenuItemList
            key={index}
            title={item.title}
            Icon={item.icon}
            linkTo={item.linkTo}
            target={item.target}
          />
        ))}
      </ul>
    </div>
  );
};

MenuList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      icon: PropTypes.node,
      onClick: PropTypes.func,
    })
  ),
};

export default MenuList;
