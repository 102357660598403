import React from "react";
import { useMap } from "react-leaflet";

const Panner = ({ centerCoordinates }) => {
  const map = useMap();
  if (centerCoordinates !== undefined) {
    map.panTo(centerCoordinates);
  }
  return null;
};

export default Panner;
