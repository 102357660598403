import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { MapContainer, TileLayer } from "react-leaflet";

import Layout from "../layouts/main";
import LocationSelector from "./_LocationSelector";
import DraggableMarker from "./_DraggableMarker";
import Panner from "./_Panner";
import Button from "../../components/Button";
import appRoute from "../../utils/appRoute";

const defaultCoordinates = [39.95167975, -75.1648285];

export const SELECT_LOCATION_PERSIST_KEY = "forms.pickup.select_location";

const SelectLocation = () => {
  const [markerCoordinates, setMarkerCoordinates] = useState(
    defaultCoordinates
  );

  const [mapCenterCoordinates, setMapCenterCoordinates] = useState(
    defaultCoordinates
  );
  const [hasUsedGeolocation, setHasUsedGeolocation] = useState(false);

  const [searchQuery, setSearchQuery] = useState("");

  let history = useHistory();

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      async (position) => {
        if (hasUsedGeolocation === false) {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
          setHasUsedGeolocation(true);
          setMarkerCoordinates([latitude, longitude]);
          setMapCenterCoordinates([latitude, longitude]);
          let response = await fetch(
            `https://fc-serve.herokuapp.com/mapbox/geocode_reverse?latitude=${latitude}&longitude=${longitude}`
          );
          const body = await response.json();
          if (response.status === 200) {
            const pickupAddress = body[0].features[0].place_name;
            setSearchQuery(pickupAddress);
          } else {
            console.error("Unable to reverse geocode coordinates");
          }
        }
      },
      (error) => console.error(error)
    );
  });

  const handleLocationSelect = (latitude, longitude, info) => {
    setSearchQuery(info.inputValue);
    setMarkerCoordinates([latitude, longitude]);
    setMapCenterCoordinates([latitude, longitude]);
  };

  const handleMarkerDragEnd = async (latitude, longitude) => {
    setMarkerCoordinates([latitude, longitude]);
    setMapCenterCoordinates([latitude, longitude]);
    let response = await fetch(
      `https://fc-serve.herokuapp.com/mapbox/geocode_reverse?latitude=${latitude}&longitude=${longitude}`
    );
    const body = await response.json();
    if (response.status === 200) {
      const pickupAddress = body[0].features[0].place_name;
      setSearchQuery(pickupAddress);
    } else {
      console.error("Unable to reverse geocode coordinates");
    }
  };

  const storeData = async () => {
    let response = await fetch(
      `https://fc-serve.herokuapp.com/mapbox/geocode_reverse?latitude=${markerCoordinates[0]}&longitude=${markerCoordinates[1]}`
    );
    const body = await response.json();
    if (response.status === 200) {
      const data = JSON.stringify({
        latitude: markerCoordinates[0],
        longitude: markerCoordinates[1],
        mapboxInfo: body[0].features,
        pickupAddress: body[0].features[0].place_name,
      });
      window.localStorage.setItem(SELECT_LOCATION_PERSIST_KEY, data);
    } else {
      console.error("Unable to reverse geocode coordinates");
    }
  };
  const handleNext = () => {
    storeData();
    history.push(appRoute("forms.pickup.details"));
  };

  const mainDivStyle = {
    height: window.innerHeight,
  };

  return (
    <Layout padding="none">
      <div className="flex flex-col" style={mainDivStyle}>
        <h3 className="text-lg mx-5 mt-5 mb-2 leading-6 font-medium text-gray-900">
          Set Pickup Location
        </h3>
        <LocationSelector
          onLocationSelect={handleLocationSelect}
          searchQuery={searchQuery}
          onSearchQueryChange={setSearchQuery}
        />
        <MapContainer
          center={{ lat: markerCoordinates[0], lng: markerCoordinates[1] }}
          zoom={15}
          scrollWheelZoom={false}
          className="w-full h-full flex-grow"
        >
          <TileLayer
            attribution='<a href="https://www.openstreetmap.org/">OpenStreetMap</a> | <a href="https://www.mapbox.com/">Mapbox</a>'
            url="https://api.mapbox.com/styles/v1/foodconnectgroup/ckhnyb23j0xxn1alebg9zsnkr/tiles/256/{z}/{x}/{y}{r}?access_token=pk.eyJ1IjoiZm9vZGNvbm5lY3Rncm91cCIsImEiOiJja2hudm96eDUwNGQ1MnJxcWlheTM5NmpxIn0.huXSASgNozPlOsVNta70oQ"
          />
          <Panner centerCoordinates={mapCenterCoordinates} />
          <DraggableMarker
            markerCoordinates={markerCoordinates}
            onMarkerDragEnd={handleMarkerDragEnd}
          />
        </MapContainer>
        <div className="mt-6 m-5 text-center">
          <Button variant="primary" width="full" onClick={handleNext}>
            Next
          </Button>
        </div>
      </div>
    </Layout>
  );
};

export default SelectLocation;
