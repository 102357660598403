import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

const RadioGroup = ({
  label,
  name,
  description,
  options,
  errorMessage,
  inputRef,
}) => {
  const hasError = !!errorMessage;
  const inputClassNames = classnames(
    "form-radio h-4 w-4 transition duration-150 ease-in-out",
    {
      "text-red-600": hasError,
      "text-orange-600": !hasError,
    }
  );
  const labelClassNames = classnames(
    "block text-sm leading-5 font-medium text-gray-700",
    {
      "text-red-600": hasError,
      "text-gray-700": !hasError,
    }
  );
  return (
    <fieldset>
      <legend className="text-base font-medium text-gray-900">{label}</legend>
      {description && (
        <p className="text-sm leading-5 text-gray-500">{description}</p>
      )}
      {hasError && (
        <p className="mt-1 text-sm text-red-600" id="email-error">
          {errorMessage}
        </p>
      )}
      <div className="mt-1">
        {options.map(({ label: optionLabel, value, uniqueId }) => {
          return (
            <div key={uniqueId} className="mt-1 flex items-center">
              <input
                id={uniqueId}
                name={name}
                type="radio"
                value={value}
                className={inputClassNames}
                ref={inputRef}
              />
              <label htmlFor={uniqueId} className="ml-3">
                <span className={labelClassNames}>{optionLabel}</span>
              </label>
            </div>
          );
        })}
      </div>
    </fieldset>
  );
};

RadioGroup.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  errorMessage: PropTypes.string,
  inputRef: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      uniqueId: PropTypes.string.isRequired,
    })
  ),
};
export default RadioGroup;
