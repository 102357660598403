import React from "react";
import PropTypes from "prop-types";

import Link from "../Link";

import { ReactComponent as CaretIcon } from "../../images/caret_icon.svg";

const MenuItemList = ({ title, Icon, linkTo, target = "_self" }) => {
  return (
    <li className="border-t border-gray-200">
      <Link
        linkTo={linkTo}
        target={target}
        className="block hover:bg-gray-50 focus:outline-none focus:bg-gray-50 transition duration-150 ease-in-out"
      >
        <div className="flex items-center px-4 py-4 sm:px-6">
          <div className="min-w-0 flex-1 flex items-center">
            {Icon && (
              <div className="flex-shrink-0">
                <Icon className="w-8 h-8" />
              </div>
            )}
            <div className="min-w-0 flex-1 px-4">
              <div>
                <div className="text-lg leading-5 font-medium text-orange-600">
                  {title}
                </div>
              </div>
            </div>
          </div>
          <div>
            <CaretIcon />
          </div>
        </div>
      </Link>
    </li>
  );
};

MenuItemList.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.node,
  onClick: PropTypes.func,
};
export default MenuItemList;
