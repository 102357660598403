import React from "react";
import { uniqueId } from "lodash";
import PropTypes from "prop-types";
import classnames from "classnames";

const Checkbox = ({
  label,
  name,
  description,
  inputRef,
  disabled = false,
  onClick,
}) => {
  const id = uniqueId();
  const labelClasses = classnames("font-medium", {
    "text-gray-700": !disabled,
    "text-gray-500": disabled,
  });
  const inputClasses = classnames(
    "form-checkbox h-4 w-4  transition duration-150 ease-in-out",
    {
      "text-orange-600": !disabled,
      "text-gray-100": disabled,
    }
  );

  return (
    <div onClick={onClick} className="mt-1 relative flex items-start">
      <div className="flex items-center h-5">
        <input
          id={id}
          type="checkbox"
          name={name}
          className={inputClasses}
          ref={inputRef}
          disabled={disabled}
        />
      </div>
      <div className="ml-3 text-sm leading-5">
        <label htmlFor={id} className={labelClasses}>
          {label}
        </label>
        {description && <p className="text-gray-500">{description}</p>}
      </div>
    </div>
  );
};

Checkbox.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
};
export default Checkbox;
