import getRoute from "./getRoute";

const routes = {
  base: "",
  home: "/",
  menu: "/menu",
  contact: "/contact",
  forms: {
    base: "/forms",
    volunteer: "/volunteer",
    pickup: {
      base: "/pickup",
      location: "/location",
      details: "/details",
      more_details: "/more_details",
      discuss_details: "/discuss_details",
      contact: "/contact",
      success: "/success",
    },
  },
};

const appRoute = (path, variables = {}) => getRoute(path, routes, variables);

export default appRoute;

export const externalRoutes = {
  CONTACT: "https://www.foodconnectgroup.com/contact/",
  VOLUNTEER: "https://www.foodconnectgroup.com/volunteer/",
  FAQ: "https://www.foodconnectgroup.com/faqs/",
  REQUEST_FOOD: "https://www.foodconnectgroup.com/receive-food/",
  SPONSOR_MEALS: "https://www.foodconnectgroup.com/donate/",
};
