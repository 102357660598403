import React from "react";
import PropTypes from "prop-types";
import { uniqueId } from "lodash";
import classnames from "classnames";

import { ReactComponent as ValidationErrorIcon } from "../images/validation_error_icon.svg";

const TextArea = ({
  label,
  placeholder,
  name,
  errorMessage,
  inputRef,
  rows = 3,
}) => {
  const id = uniqueId();
  const hasError = !!errorMessage;
  const textAreaClassNames = classnames(
    "form-textarea block w-full transition duration-150 ease-in-out",
    {
      "border-red-300": hasError,
      "text-red-900": hasError,
      "placeholder-red-300": hasError,
      "focus:border-red-300": hasError,
      "focus:shadow-outline-red": hasError,
    }
  );
  return (
    <div>
      <label
        htmlFor={id}
        className="block text-sm font-medium leading-5 text-gray-700"
      >
        {label}
      </label>
      {hasError && (
        <p className="mt-2 text-sm text-red-600" id="email-error">
          {errorMessage}
        </p>
      )}
      <div className="mt-1 rounded-md relative shadow-sm">
        <textarea
          id={id}
          rows={rows}
          className={textAreaClassNames}
          placeholder={placeholder}
          name={name}
          ref={inputRef}
        ></textarea>
        {hasError && (
          <div className="absolute bottom-0 right-0 pb-2 pr-3 flex pointer-events-none">
            <ValidationErrorIcon />
          </div>
        )}
      </div>
    </div>
  );
};

TextArea.propTypes = {
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  name: PropTypes.string.isRequired,
};

export default TextArea;
