import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import { object, string, date } from "yup";
import { get, uniqueId } from "lodash";
import useFormPersist from "react-hook-form-persist";
import { DateTime } from "luxon";
import { useHistory } from "react-router-dom";

import Layout from "../layouts/main";
import RadioGroup from "../../components/RadioGroup";
import Button from "../../components/Button";
import DateTimeInput from "../../components/DateTimeInput";
import appRoute from "../../utils/appRoute";

const pickupOptions = [
  { label: "Car", value: "CAR", uniqueId: uniqueId() },
  { label: "Van/SUV", value: "VAN", uniqueId: uniqueId() },
  { label: "Truck", value: "TRUCK", uniqueId: uniqueId() },
];

export const DISCUSS_DETAILS_PERSIST_KEY = "forms.pickup.discuss_details";

const DiscussDetails = () => {
  const history = useHistory();
  const minPickupTime = DateTime.local().plus({ minutes: 30 }).toJSDate();

  const validationSchema = object().shape({
    vehicle: string().required().label("Pickup vehicle"),
    pickupAfter: date().min(
      minPickupTime,
      "Pickup after field must be half an hour from now"
    ),
    pickupBefore: date().test({
      name: "afterEarliestPickup",
      test(pickupBefore) {
        const { pickupAfter } = this.parent;
        return pickupBefore > pickupAfter;
      },
      message: "Should be later than earliest pickup time",
    }),
  });

  const { watch, setValue, register, handleSubmit, errors } = useForm({
    resolver: yupResolver(validationSchema),
  });

  useFormPersist(
    DISCUSS_DETAILS_PERSIST_KEY,
    { watch, setValue },
    { storage: window.localStorage }
  );

  const onSubmit = () => {
    history.push(appRoute("forms.pickup.contact"));
  };

  return (
    <Layout>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <div>
            <div>
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Let's discuss pickup details
              </h3>
            </div>
            <div className="mt-6 grid grid-cols-12 row-gap-6">
              <div className="col-span-12">
                <RadioGroup
                  label="How should we pick it up?"
                  options={pickupOptions}
                  name="vehicle"
                  inputRef={register}
                  errorMessage={get(errors, "vehicle.message")}
                />
              </div>
              <div className="col-span-12">
                <DateTimeInput
                  label="Pickup After"
                  name="pickupAfter"
                  placeholder="July 12th, 2020 7:00PM"
                  inputRef={register}
                  errorMessage={get(errors, "pickupAfter.message")}
                />
              </div>
              <div className="col-span-12">
                <DateTimeInput
                  label="Pickup Before"
                  name="pickupBefore"
                  placeholder="July 12th, 2020 9:00PM"
                  inputRef={register}
                  errorMessage={get(errors, "pickupBefore.message")}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="mt-24"></div>
        <div className="mt-8 border-t border-gray-200 pt-5">
          <div className="flex justify-end">
            <Button variant="white" linkTo={appRoute("menu")}>
              Cancel
            </Button>
            <span className="ml-3">
              <Button variant="primary" type="submit">
                Next
              </Button>
            </span>
          </div>
        </div>
      </form>
    </Layout>
  );
};

export default DiscussDetails;
