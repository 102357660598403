import React from "react";

import Layout, { PADDING_IN_PX } from "../layouts/main";
import StatsBox from "./StatsBox";
import Button from "../../components/Button";
import appRoute from "../../utils/appRoute";
import Icon from "../../images/icon.png";

const Landing = () => {
  const mainDivStyle = {
    height: window.innerHeight - 2 * PADDING_IN_PX,
  };
  return (
    <Layout>
      <div className="flex flex-col" style={mainDivStyle}>
        <div>
          <img src={Icon} alt="icon" className="w-40 lg:w-56 m-auto mt-2" />
        </div>
        <StatsBox />
        <div className="bg-white bg-opacity-50">
          <div className="mt-10 p-3">
            <span className="font-bold">Our mission</span> is to{" "}
            <span className="font-bold">
              get food into the hands of those who need it most.
            </span>{" "}
            We identify real time needs of food recipients and work with meal
            sponsors, donors, and volunteers to make sure no one goes hungry
            tonight. We build tools and technology to inspire a world of
            connection. Join our journey.
          </div>
        </div>
        <div className="mt-6 text-center">
          <Button variant="primary" width="w-9/12" linkTo={appRoute("menu")}>
            Count Me In
          </Button>
        </div>
      </div>
    </Layout>
  );
};

export default Landing;
