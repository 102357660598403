import React, { useState } from "react";
import { useForm } from "react-hook-form";
import useFormPersist from "react-hook-form-persist";
import { useHistory } from "react-router-dom";

import Layout from "../layouts/main";
import Checkbox from "../../components/Checkbox";
import Button from "../../components/Button";
import HomemadeFoodModal from "./_HomemadeFoodModal";
import { PICKUP_DETAILS_PERSIST_KEY } from "./PickupDetails";
import appRoute from "../../utils/appRoute";

export const MORE_DETAILS_PERSIST_KEY = "forms.more_details";

const MoreDetails = () => {
  const history = useHistory();
  const [homemadeModalVisible, setHomemadeModalVisible] = useState(false);

  const { watch, setValue, register, handleSubmit, errors } = useForm({});

  useFormPersist(
    MORE_DETAILS_PERSIST_KEY,
    { watch, setValue },
    { storage: window.localStorage }
  );

  const pickupDetails = JSON.parse(
    window.localStorage.getItem(PICKUP_DETAILS_PERSIST_KEY)
  );
  const { title } = pickupDetails;

  const onSubmit = () => {
    history.push(appRoute("forms.pickup.discuss_details"));
  };

  return (
    <Layout>
      <HomemadeFoodModal
        open={homemadeModalVisible}
        setOpen={setHomemadeModalVisible}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <div>
            <div>
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Tell us more about {title}
              </h3>
            </div>
            <div className="mt-6 grid grid-cols-12 row-gap-6">
              <div className="col-span-12">
                <Checkbox
                  name="individuallyPackaged"
                  label="Individually Packaged"
                  inputRef={register}
                />
                <Checkbox
                  name="homemadeFood"
                  label="Homemade Food"
                  disabled={true}
                  inputRef={register}
                  onClick={() => {
                    setHomemadeModalVisible(true);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="mt-24"></div>
        <div className="mt-8 border-t border-gray-200 pt-5">
          <div className="flex justify-end">
            <Button variant="white" linkTo={appRoute("menu")}>
              Cancel
            </Button>
            <span className="ml-3">
              <Button variant="primary" type="submit">
                Next
              </Button>
            </span>
          </div>
        </div>
      </form>
    </Layout>
  );
};

export default MoreDetails;
