import React from "react";

import Button from "../../components/Button";
import Layout from "../layouts/main";
import { ReactComponent as DonateFoodIcon } from "../../images/donate_food_icon.svg";
import appRoute from "../../utils/appRoute";
import { PICKUP_CONTACT_PERSIST_KEY } from "./PickupContact";

const PickupSuccess = () => {
  const { pickupPhone } = JSON.parse(
    window.localStorage.getItem(PICKUP_CONTACT_PERSIST_KEY)
  );
  return (
    <Layout>
      <div>
        <DonateFoodIcon className="h-32 mt-8 w-full object-contain" />
        <h1 className="text-center mt-7 text-2xl font-bold leading-7 text-gray-900">
          Whoo hoo!
        </h1>
        <p className="mt-3">
          Our dispatchers are finding the best place to deliver the food.
        </p>
        <p className="mt-6">
          We will call or text you at{" "}
          <span className="font-bold">{pickupPhone} </span>
          when we are able to confirm your pickup.
        </p>
        <div className="mt-10">
          <Button width="full" variant="primary" linkTo={appRoute("menu")}>
            Done
          </Button>
        </div>
      </div>
    </Layout>
  );
};

export default PickupSuccess;
